import { nextTick } from "vue";
import useBaseUtils from "~/functions/useBaseUtils";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import SimpleModel from "~/components/valuationModels/SimpleModel.vue";
import { templates } from "~/utils/valuationTransform/peValuationTemplates";
import GenericLoader from "~/components/valuationModels/GenericLoader.vue";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      customGroup = _useBaseUtils.customGroup,
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
      parseUtcDateTime = _useDateTimeFormatter.parseUtcDateTime;
    var $route = useRoute();
    var queryTitle = $route.query.title;
    var _useCiqTableOptions = useCiqTableOptions({
        tableType: "estimates",
        storeModule: "ciq"
      }),
      currentIso = _useCiqTableOptions.currentIso;
    var loadingStore = computed(function () {
      return $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingValuation || $store.state.ciq.fetchingFinancials || $store.state.ciq.fetchingQuotes;
    });
    var initialLoad = computed(function () {
      var _$store$state$valuati;
      return (_$store$state$valuati = $store.state.valuationModel.loading) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati.initialLoad;
    });
    watch(loadingStore, function (newState) {
      if (newState === false) {
        if ($route.query.modelId) {
          $store.dispatch("valuationModel/retrieveModel", {
            uuid: $route.query.modelId,
            userTier: customGroup.value,
            isDev: dev.value,
            simple: true
          });
          return;
        }
        nextTick(function () {
          $store.dispatch("valuationModel/setNewModel", {
            templates: templates,
            $store: $store,
            isDev: dev.value,
            amountOfYearsForward: 6,
            parseUtcDateTime: parseUtcDateTime,
            utcEpochToShortDate: utcEpochToShortDate.value,
            currentIso: currentIso.value,
            titleOverride: queryTitle,
            userTier: customGroup.value,
            refCode: refCode.value,
            simple: true
          });
        });
      }
    }, {
      immediate: true
    });
    return {
      __sfc: true,
      $store: $store,
      customGroup: customGroup,
      dev: dev,
      refCode: refCode,
      utcEpochToShortDate: utcEpochToShortDate,
      parseUtcDateTime: parseUtcDateTime,
      $route: $route,
      queryTitle: queryTitle,
      currentIso: currentIso,
      loadingStore: loadingStore,
      initialLoad: initialLoad,
      SimpleModel: SimpleModel,
      GenericLoader: GenericLoader
    };
  }
};